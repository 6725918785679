import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Form from './form'
import './contact.css'

export class Contact extends Component {
    static displayName = Contact.name;

    render() {
        return (
            <div className="main">

                <Helmet>
                    <title>Antoine HOELLINGER | Contact</title>
                </Helmet>

                <Form />
            </div>
        );
    }
}
