import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './legal.css';
import { withTranslation } from 'react-i18next';

const enhance = withTranslation();

class Legal extends Component {
    static displayName = Legal.name;

    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            mail: '',
        };
    }

    handlePhoneClick = () => {
        this.setState({ phone: '+33 6 63 79 46 13' });
    };

    handleMailClick = () => {
        this.setState({ mail: 'antoine.hlgr@gmail.com' });
    };

    render() {
        const { t } = this.props; // Utilisation de t à partir des props

        const { phone, mail } = this.state;
        return (
            <div className="main">
                <Helmet>
                    <title>Antoine HOELLINGER | Contact</title>
                </Helmet>

                <div className="title">
                    <h1>{t("Mentions légales")}</h1>
                    <div className="subline"></div>
                </div>

                <div className="legal">
                    <p>{t("Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs de ce site l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.")}</p>
                </div>

                <div className="legal">
                    <h3>{t("Identité")}</h3>
                    <p>{t("Ce site internet est géré et édité par Antoine HOELLINGER")}.</p>
                </div>

                <div className="legal">
                    <h3>{t("Coordonnées")}</h3>
                    <p>{t("Pour tout type d'information, vous pouvez me contacter par")} :</p>
                    <ul>
                        <li>{t("Lieu")} : Paris</li>
                        <li>{t("N° de téléphone")} : <p style={{ cursor: 'pointer' }} onClick={this.handlePhoneClick}>{phone ? phone : t('click to see')}</p></li>
                        <li>{t("Adresse e-mail")} : <p style={{ cursor: 'pointer' }} onClick={this.handleMailClick}>{mail ? mail : t('click to see')}</p></li>
                    </ul>
                </div>

                <div className="legal">
                    <h3>{t("Mentions relatives à l'hébergement du site")}</h3>
                    <p>{t("Identité")}</p>
                </div>

                <div className="legal">
                    <h3>{t("Mentions commerciales")}</h3>
                    <p>{t("L'activité associée à l'auto-entreprise 'Antoine HOELLINGER' est identifiée sous les caractères suivants")} :</p>
                    <ul>
                        <li>{t("Date de création : ")}03/11/2020</li>
                        <li>{t("NAF")} : 6201Z PROGRAMMATION INFORMATIQUE</li>
                        <li>{t("N° SIRET")} : 89068921900016 02</li>
                    </ul>
                </div>

                <div className="legal">
                    <h3>{t("Crédit d'inspiration")}</h3>
                    <ul>
                        <li>
                            <a href="http://exill.dk/demo/kitzu/template/index.html" target="_blank">{t("Le Portfolio d'Emma Smith")}</a>
                        </li>
                        <li>
                            <a href="https://blog.stackfindover.com/" target="_blank">{t("Animations des textes")}</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default enhance(Legal); 
