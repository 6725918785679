import React from "react";
import { useTranslation } from "react-i18next";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, timelineOppositeContentClasses } from '@mui/lab';
import { Typography, Accordion, AccordionDetails, AccordionSummary, Button, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PiCertificateFill } from "react-icons/pi";
import { FaVanShuttle } from "react-icons/fa6";

function Education() {

    const { t, i18n } = useTranslation();

    const educationData = [
        {
            title: "Master 2 Marketing Digital et E-Commerce",
            time: 1,
            school: "Université Grenoble Alpes ( UGA )",
            start_date: "2019",
            end_date: "2020",
            message: "L'objectif de la formation est ainsi de développer les compétences des étudiants en conception de stratégies de marketing digital et d'e-commerce dans les entreprises. La formation aborde les concepts et les outils pour y parvenir : e-marketing, comportement du e-consommateur, l'environnement légal, ...",
            link: "https://formations.univ-grenoble-alpes.fr/fr/catalogue-2021/master-XB/master-marketing-vente-IDIV7Q1Y/parcours-marketing-digital-et-consommateur-connecte-2e-annee-IYED42OE.html",
            localisation: "France",
            icon: <PiCertificateFill className='sub_title_svg' />
        },
        {
            title: "Année de césure",
            time: 1,
            start_date: "2018",
            end_date: "2019",
            message: "J'ai passé un an en Nouvelle-Zélande pour m'immerger dans la culture locale et améliorer mon anglais. Ce séjour m'a permis de découvrir les traditions maories tout en perfectionnant mes compétences linguistiques au contact de personnes du monde entier. Une expérience enrichissante à bien des égards.",
            link: "https://www.newzealand.com/",
            localisation: "Nouvelle-Zélande",
            icon: <FaVanShuttle className='sub_title_svg' />
        },
        {
            title: "Master Marketing, Ventes",
            time: 1,
            school: "Université Grenoble Alpes ( UGA )",
            start_date: "2017",
            end_date: "2018",
            message: "Le master marketing, vente de Grenoble IAE reste un master généraliste au sens où ses différents parcours ciblent, collectivement, une large palette de métiers du marketing. Chaque parcours de 2e année de master, par contre, est spécialisé sur un type spécifique de débouchés : Le quanti, Ingénerie des marques, Communication et marketing digitale, Marketing digital et consommateur connecté et Customer relationships marketing",
            link: "https://formations.univ-grenoble-alpes.fr/fr/catalogue-2021/master-XB/master-marketing-vente-IDIV7Q1Y.html",
            localisation: "France",
            icon: <PiCertificateFill className='sub_title_svg' />
        },
        {
            title: "Bachelor of Business",
            time: 1,
            start_date: "2016",
            end_date: "2017",
            message: "Ce programme offre une formation commerciale générale pour permettre de travailler avec succès dans les affaires avec une variété de sujets commerciaux pour développer une compréhension générale de l'environnement administratif, économique, juridique et social dans lequel les entreprises opèrent.",
            link: "https://www.gmit.ie/bachelor-of-business",
            school: "Galway-Mayo Institute of Technology (GMIT)",
            localisation: "Irlande",
            icon: <PiCertificateFill className='sub_title_svg' />
        },
        {
            title: "D.U.T Techniques de commercialisation",
            time: 2,
            start_date: "2014",
            end_date: "2016",
            message: "L'objectif de ce diplome est de former les étudiants pour qu’ils puissent rapidement occuper des fonctions prioritairement commerciales.",
            link: "https://www.orientation.com/diplomes/dut-techniques-de-commercialisation",
            school: "I.U.T de Valence",
            localisation: "France",
            icon: <PiCertificateFill className='sub_title_svg' />
        },
        {
            title: "BAC Economique et Social",
            time: 1,
            start_date: "2014",
            end_date: "2014",
            message: "",
            link: "",
            school: "",
            localisation: "France",
            icon: <PiCertificateFill className='sub_title_svg' />
        }
    ];


        return (
            <>
                <h2 style={{textAlign:'center'}}>{t("Mes diplômes")} </h2>

                    <div className="education_container">

                        <Timeline
                        sx={{
                            margin:'auto',
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 'unset',
                                },
                                [`& .${TimelineContent.root}`]: {
                                    flexGrow: 1,
                                },
                                [`& .MuiTimelineItem-root`]: {
                                    minHeight: '120px',
                                    maxWidth: '1000px'
                                },
                            }}
                        >
                            {educationData.map((item, index) => (
                            <TimelineItem>
                                    <TimelineOppositeContent sx={{ marginTop: '8px' }} >
                                        <Typography className='sub_title' id='sub_title_date' sx={{ backgroundColor: 'var(--second)', padding: '5px 10px', borderRadius: '10px' }}>{item.start_date} - {item.end_date}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                        <TimelineDot  sx={{ backgroundColor: 'var(--second)', padding: '8px' }} >
                                            {item.icon}
                                        </TimelineDot>
                                   {index !== educationData.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                    <TimelineContent>
                                        <Accordion id="education_accordion" >
                                            <AccordionSummary
                                            className='accordion_summary'
                                                sx={{
                                                    '&:focus': {
                                                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                                                    },
                                                    '& .MuiAccordionSummary-content': {
                                                        margin: '0px',
                                                    },
                                                }}
                                                expandIcon={<ArrowDropDownIcon style={{ color:'var(--second)' }} />}
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                            >
                                                <Typography component="span" className='sub_title' >{t(item.title)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                className='accordion_details'
                                                sx={{ backgroundColor: 'var(--second)', display:'flex', flexDirection:'column', gap: '10px', padding: '25px 20px' }}
                                            >
                                                <Typography>📍 {t(item.school)}{item.school && ', '}{t(item.localisation)} </Typography>
                                                <Typography>📆 {t(item.time)} {t(item.time > 1 ? 'ans' : 'an')}</Typography>
                                                <Typography>{t(item.message)}</Typography>
                                                {item.link && <Button variant="contained"
                                                    sx={{
                                                        marginTop: '20px',
                                                        color: 'var(--primary_text)',
                                                        backgroundColor: 'var(--primary)',
                                                        '&:hover': {
                                                            backgroundColor: 'var(--primary_hover)',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:active': {
                                                            boxShadow: 'none',
                                                            backgroundColor: 'var(--primary_hover)',
                                                        },
                                                        '&:focus': {
                                                            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                                                        },
                                                    }}
                                                    onClick={() => window.open(item.link, '_blank', 'noopener,noreferrer')}>{t('En savoir plus')}
                                                </Button>}
                                            </AccordionDetails>
                                        </Accordion>
                                    </TimelineContent>
                            </TimelineItem>
                                ))}
                        </Timeline>

                </div>

            </>
        )
    }

export default Education
