import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './projects.css';
import { useTranslation } from "react-i18next";
import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import moment from "moment";

export const Projects = () => {
    const { t } = useTranslation();

    const projects = [
        {
            project_name: "PRAGMANTIC",
            dev_stack: "WIX",
            created_at: "12/31/2020",
            updated_at: "03/31/2020",
            information: "Pour renforcer la visibilité du groupe PRAGMANTIC, j’ai conçu et développé son site internet vitrine. Ce projet a impliqué un travail approfondi sur le référencement naturel (SEO) afin d’optimiser sa présence en ligne, ainsi qu’une mise en page soignée respectant fidèlement la charte graphique du groupe. L’objectif était d’offrir une vitrine moderne et efficace, alignée avec l’identité et les valeurs de PRAGMANTIC.",
            description_1: "Ce site a été réalisé en partie via WIX, une plateforme en ligne permettant de concevoir des sites web de manière intuitive grâce à un éditeur en glisser-déposer. Ce choix technologique a été spécifiquement demandé par le groupe afin de leur offrir une autonomie complète sur les futures modifications du site, sans nécessiter de compétences techniques avancées.",
            public_url: "https://www.pragmantic.biz/",
            image: "/assets/images/pragmantic.png"
        },
        {
            project_name: "EasyInvoice",
            dev_stack: "React, Node, NGINX",
            created_at: "01/12/2023",
            information: "Ma passion pour l'entrepreneuriat m'a conduit à créer une solution innovante pour répondre à un besoin concret : la gestion efficace des factures pour les auto-entrepreneurs en France. En tant qu'indépendant, j'ai rencontré des difficultés à trouver une plateforme répondant pleinement à mes besoins, alors j'ai décidé de concevoir ma propre application.",
            description_1: "Partant de mon propre besoin de créer, gérer et stocker des factures, j'ai développé une application qui simplifie cette tâche cruciale pour tout entrepreneur. Cette initiative découle d'une lacune sur le marché : l'absence d'une solution intuitive et complète pour les auto-entrepreneurs français.",
            description_2: "Mon application offre une interface intuitive pour créer des factures professionnelles en quelques étapes simples. Elle permet également de gérer plusieurs entreprises depuis une même plateforme, garantissant un suivi précis des paiements et une organisation optimale. Le stockage sécurisé des factures offre un accès immédiat à toute donnée importante, à tout moment.Connectivité ClientUn aspect essentiel de cette application est sa capacité à établir des liens directs avec les clients. Elle facilite un suivi transparent des factures, favorisant ainsi des échanges fluides et une meilleure communication pour un service client de qualité.L'Engagement envers la SimplicitéMon objectif principal était de créer une solution facile à utiliser et personnalisable selon les besoins spécifiques de chaque utilisateur. L'application s'adapte à l'identité de l'entreprise, offrant ainsi une expérience fluide et intuitive.",
            url: "https://api.github.com/repos/savemyfiles/antoine-hoellinger.cloud",
            headers: { Authorization: `token ${process.env.REACT_APP_GH_READ_TOKEN}` },
            public_url: "https://appinvoice.antoine-hoellinger.cloud",
            image: "/assets/images/Logo-noir.png"
        }
    ];

    const [projectsData, setProjectsData] = useState(projects);

    const fetchReleaseData = async (url, headers) => {
        try {
            const response = await fetch(url, {
                headers: headers
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Erreur lors de la r�cup�ration des données de release :', error);
            return {};
        }
    };

    useEffect(() => {
        const fetchAllProjects = async () => {
            const fetchedProjects = await Promise.all(
                projects.map(async (project) => {
                    const data = await fetchReleaseData(project.url, project.headers);
                    return { ...project, ...data };
                })
            );
            setProjectsData(fetchedProjects);
        };

        fetchAllProjects();
    }, []);

    console.log(projectsData)

    return (
        <div className="main">
            <Helmet>
                <title>Antoine HOELLINGER | Projects</title>
            </Helmet>

            <div className="title">
                <h1>{t("Mes projets personnels")}</h1>
            </div>

            {projectsData.map((item, index) => (
               
                <section key={index}>

                    <div className='project-container' style={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse", borderBottom: (index + 1 !== projectsData.length) ? "2px solid var(--primary)" : "", paddingBottom: (index + 1 !== projectsData.length) ? "60px" : "0px" }}>
                        <div className='image-container' style={{ justifyContent: index % 2 === 0 ? "left" : "right", display:'flex'}}>
                            <Card sx={{ maxWidth: 450, backgroundColor: 'var(--second)', boxShadow: 'var(--shadow)'}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={item.image}
                                    title={item.tite}
                                    sx={{ objectFit: "contain", backgroundColor: 'white' }}
                                />
                                <CardContent sx={{ padding: '25px' }}>
                                    <Typography gutterBottom style={{ color: 'var(--primary)' }} variant="h4" component="div">
                                        {item.project_name || 'Nom du projet non défini'}
                                    </Typography>
                                    <ul className='project_card_content_list' style={{ color: 'white' }}>
                                        <li><span className='card_p_title'>{t("Principal langage utilisé")} :</span> {item.dev_stack}</li>
                                        <li><span className='card_p_title'>{t("Date de création")} :</span> {moment(item.created_at).format('DD/MM/YYYY')}</li>
                                        {item.updated_at && <li><span className='card_p_title'>{t("Dernière mise à jour")} :</span> {moment(item.updated_at).format('DD/MM/YYYY')}</li>}
                                    </ul>
                                </CardContent>
                                <CardActions sx={{ padding: '25px' }}>
                                    {item.private === false && item.html_url && (
                                        <a className="main_button" href={item.html_url} target="_blank" rel="noopener noreferrer">
                                            {t("Voir sur")} GitHub
                                        </a>
                                    )}
                                    <a className="main_button" target="_blank" rel="noopener noreferrer" href={item.public_url}>
                                        {t("En savoir plus")}
                                    </a>
                                </CardActions>
                            </Card>
                        </div>

                        <div className='project-side-description' >
                            <h3>{t("Information sur le projet")}</h3>
                            <p>{t(item.information) || t("Aucune information disponible.")}</p>

                            <h3>{t("Origine et description du projet")}</h3>
                            <p>{t(item.description_1) || t("Pas de description fournie.")}</p>
                            {item.description_2 && <p>{t(item.description_2)}</p>}
                        </div>
                    </div>

                </section>
            ))}
        </div>
    );
};
