import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Select, Box, Typography, Slide, useScrollTrigger, Divider, MenuItem } from '@mui/material';
import { FaLanguage } from 'react-icons/fa';
import Logo from '../../assets/images/IcoLogo.svg';
import { useTranslation } from "react-i18next";

import { Home, Menu, AccountCircle, Work, RocketLaunch, Email } from '@mui/icons-material';
import { FaGithub, FaFreeCodeCamp, FaLinkedin, FaRegCommentAlt } from 'react-icons/fa';


const NavMenu = () => {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState(() => localStorage.getItem('lang') || 'fr');
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
    }, [lang]);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const trigger = useScrollTrigger();

    const menu = [
        {
            label: 'Home', link: '/', icon: <Home />
        },
        { label: 'A propos', link: '/about', icon: <AccountCircle /> },
        { label: 'Work & Services', link: '/services', icon: <Work /> },
        { label: 'Personal projects', link: '/projects', icon: <RocketLaunch /> },
        { label: 'Contact', link: '/contact', icon: <Email /> }
    ];

    return (
        <Slide  direction="down" in={!trigger}>
            <AppBar position="sticky" className='nav_bar'>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Link to="/">
                            <img src={Logo} alt="Logo" style={{ height: 40 }} />
                        </Link>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap : 2 }}>

                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                        <FaLanguage style={{ fontSize: "1.5rem", color: '#14f3db' }} />
                            <Select
                            MenuProps={{ disableScrollLock: true, }}
                            value={lang}
                            onChange={(e) => setLang(e.target.value)}
                            sx={{
                                color: 'white',
                                '& .MuiSvgIcon-root': { color: 'white' },
                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                            }}
                        >
                            <MenuItem value='fr'>{t("Français")}</MenuItem>
                            <MenuItem value='en'>{t("English")}</MenuItem>
                            <MenuItem value='es'>{t("Español")}</MenuItem>
                        </Select>
                    </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }} id='pragmantic_container'>
                        <Typography component="a" href="https://www.pragmantic.biz/" target="_blank" sx={{ color: 'white', textDecoration: 'none' }}>
                                <span style={{ color: 'white', fontWeight: 600 }}>PRAGMA</span><span style={{ color: 'orange', fontWeight: 600 }}>NTIC </span>
                                <span class='pragmantic_partners'>Parters</span>
                        </Typography>
                    </Box>

                    </Box>

                    <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
                        <Menu />
                    </IconButton>

                </Toolbar>

                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    id='menu_open'
                    disableScrollLock={true}
                >
                    <Box sx={{ width: 250, marginBottom:'70px' }} role="presentation" className='menu_open' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <List>
                            {menu.map((item) => 
                                <ListItem className='menu_list' button component={Link} to={item.link}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {t(item.label)}
                                        </ListItemText>
                                </ListItem>
                            )}
                        </List>
                        <ul className='menu_open_links' style={{ display:'flex', width:'100%', justifyContent:'center', gap:"30px", fontSize:'25px', position:'absolute', bottom:'20px'}}>
                            <li><a href="https://www.freecodecamp.org/antoinehlg"><FaFreeCodeCamp /></a></li>
                            <li><a href="https://github.com/savemyfiles"><FaGithub /></a></li>
                            <li><a href="https://www.linkedin.com/in/antoine-hoellinger/"><FaLinkedin /></a></li>
                        </ul>
                    </Box>
                </Drawer>
            </AppBar>
        </Slide>
    );
};

export default NavMenu;
