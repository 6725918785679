import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import { Layout } from './Layout';
import { Home } from './components/home/Home';
import { About } from './components/about/about';
import { Services } from './components/services/services';
import { Projects } from './components/projects/projects';
import { Contact } from './components/contact/contact';
import Legal from './components/legal/legal';
import './App.css'


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Router>
                <Layout>
                    <Routes> 
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/legal" element={<Legal />} />
                        <Route path="/projects" element={<Projects />} />
                    </Routes>
                </Layout>
                </Router>
        );
    }
}
