import React from 'react';
import { ReactTyped } from "react-typed";
import { useTranslation } from "react-i18next";

const TextAnimation = ({ items, title }) => {

    const { t, i18n } = useTranslation();

    return (
        <div >
            <h2>
                {t(title)} 
                {' '}
                <ReactTyped
                    className='animated_text'
                    strings={items}
                    typeSpeed={80}
                    loop
                    backSpeed={50}
                    cursorChar="|"
                    showCursor={true}
                />
                {' ?'}
            </h2>
        </div>
    );
};

export default TextAnimation;