import React from 'react';
import ReactDOM from 'react-dom/client';  
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./i18n";

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement);
root.render(
        <App />
);

if (document.getElementById('email') != null) {
    document.getElementById('email').innerHTML = "antoine.hlgr@gmail.com".split("").reverse().join("");
}

if (document.getElementById('phone') != null) {
    document.getElementById('phone').innerHTML = "06 63 79 46 13".split("").reverse().join("");
}
