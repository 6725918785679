import React from "react"
import { FaGithub, FaFreeCodeCamp, FaLinkedin, FaRegCommentAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TextAnimation from "./TextAnimation"
import { IoMdCodeDownload } from "react-icons/io";


function  HomeContent() {

        return (
            <>
                <div className="lang">
                    <li><Link  to="/contact">< FaRegCommentAlt /></Link></li>
                </div>

                <div className="heroContent">

                <h1 className="heroTitle"> Antoine <span className="color">HOELLINGER</span></h1>

                    <div className="heroSubtitle">
                        <div className="hero_textcontainer ">
                            <TextAnimation items={['Specialiste Digital', 'PMO', 'Développeur web']} title="Vous avez besoin d'un" />
                        </div>
                    </div>

                    <div className="heroSubtitle home_button" style={{ marginTop: "50px" }}>
                        <span className="home_button_svg"><IoMdCodeDownload /></span>
                        <Link  to={'/about'}>C'est par ici</Link>
                    </div>

                </div>

                <ul className="socialBlock">
                    <li><a href="https://www.freecodecamp.org/antoinehlg"><FaFreeCodeCamp /></a></li>
                    <li><a href="https://github.com/savemyfiles"><FaGithub /></a></li>
                    <li><a href="https://www.linkedin.com/in/antoine-hoellinger/"><FaLinkedin /></a></li>
                </ul>

            </>
        )
    }




export default HomeContent

