    import React from "react"
import { useTranslation } from "react-i18next";


function Finance(props) {

    const { t, i18n } = useTranslation();


        return (
            <>
                <h1>{t("Mon Experience en Micro-finance")}</h1>

                <div className="container">

                    <div className="container_image" id="pmoit" >
                        <img src={require('../../../assets/images/pmoint.jpeg')} alt="PMO International" />
                    </div>

                    <div className="container_description">
                        <h2>{t("PMO International")}</h2>

                        <p>{t("Préstation dans le cadre d’un déploiement Core Model ( comprenant 1 CBS, une 10ène d'applications connexes, 1 middleware, ... ) à l’international ( 9 pays ), en tant que PMO Central pour")} :</p>
                         < ul >
                            <li>{t("Superviser et suivre les tâches centrales visant la livraison et l'alignement avec le déploiement du Core Model dans les filiales concernées. Recueillir des données sur l'exécution des Projets.")}</li>
                            <li>{t("Maitriser le planning du programme au niveau central et faire les actualisations nécessaires pour le suivi selon les directrices de la direction.")}</li>
                            <li>{t("Dans les instances de suivi des projets, identifier les risques, suivre les actions et les escalades et faire le lien entre les pays en processus de déploiement.")}</li>
                            <li>{t("En charge de la gestion et du suivi d'un projet de migration majeur de Paas à IaaS, couvrant neuf pays et englobant plus de dix applications, un middleware, et un CBS (Core Model). De la phase de benchmark de partenaire au déploiement BUILD.")}</li>
                            <li>{t("Cadrage et suivi des chantiers Infra et solutions digitales.")}</li>
                            <li>{t("Cadrage et suivi des chantiers d'intégration pour la mise en place d'API.")}</li>

                        </ul >

                        <a className="main_button" href="https://www.pragmantic.biz/">{t("En savoir plus")}</a>

                </div>
                </div>

                <div className="container container_reverse">

                    <div className="container_image" id="pmoit" >
                        <img src={require('../../../assets/images/talend.jpg')} alt="PMO Technique" />
                    </div>

                        <div className="container_description">
                        <h2>{t("Developpeur TALEND")}</h2>
                        <p>{t("En charge de projets stratégiques et techniques, incluant les tâches suivantes")} :</p>
                        < ul >
                            <li>{t("Collaboration avec des gateways WSO2 et Axway pour l'intégration des systèmes.")}</li>
                            <li>{t("Développement d'API et de jobs en Java, en assurant une gestion centralisée du code sur Git.")}</li>
                            <li>{t("Mise en place d'une stratégie de roll-out commune pour assurer l'uniformité des déploiements à travers les filiales.")}</li>
                        </ul >
                    </div>

                </div>

                </>
        )
    }

export default Finance;