import React from "react"
import { useTranslation } from "react-i18next";
import { Box, Card, CardActions, CardContent, Button, Typography, CardMedia } from '@mui/material';


function Skills(props) {

    const { t, i18n } = useTranslation();

    const tools = [
        {
            title: 'SALESFORCE',
            link: "https://www.salesforce.com/fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/640px-Salesforce.com_logo.svg.png",
        },
        {
            title: 'ZENDESK',
            link: "https://www.zendesk.fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Zendesk_primary_logo_espresso.svg/640px-Zendesk_primary_logo_espresso.svg.png",
        },
        {
            title: 'EXCEL & QUERY',
            link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/640px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png",
        },
        {
            title: 'POWER BI',
            link: "https://powerbi.microsoft.com/fr-fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/New_Power_BI_Logo.svg/640px-New_Power_BI_Logo.svg.png",
        },
        {
            title: 'TABLEAU SOFTWARE',
            link: "https://www.tableau.com/fr-fr",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Tableau_Logo.png/640px-Tableau_Logo.png",
        },
        {
            title: 'JAVASCRIPT, JAVA, PYTHON',
            link: "https://developer.mozilla.org/fr/docs/Learn/Front-end_web_developer",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/640px-Unofficial_JavaScript_logo_2.svg.png",
        },
        {
            title: 'ITSM : JIRA, SERVICE NOW',
            link: "https://www.atlassian.com/software/jira",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Jira_%28Software%29_logo.svg/1200px-Jira_%28Software%29_logo.svg.png",
        },
        {
            title: 'AZURE DEVOPS',
            link: "https://azure.microsoft.com/fr-fr/services/devops/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Microsoft_Azure_Logo.svg/1200px-Microsoft_Azure_Logo.svg.png",
        },
        {
            title: 'GIT, GITHUB, BITBUCKET',
            link: "https://github.com/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1200px-Octicons-mark-github.svg.png",
        },
        {
            title: 'TALEND TMC, ESB',
            link: "https://tmc.eu.cloud.talend.com/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/Talend_logo.svg/1024px-Talend_logo.svg.png",
        },
        {
            title: 'GATEWAY WSO2',
            link: "https://wso2.com/",
            image: "https://wso2.cachefly.net/wso2/sites/all/2023/images/webp/wso2-logo.webp",
        },
        {
            title: 'MSSQL, ORACLE, SQL',
            link: "https://www.oracle.com",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Sql_data_base_with_logo.svg/320px-Sql_data_base_with_logo.svg.png",
        }
    ];


    return (

        <>
           <div className="title">
                <h1>{t("Mes services")}</h1>
            </div>

                <div className="tools">

                    <h2 style={{ width: '100%' }}>{t('Outils maitrisés')}</h2>

                    <div className="tools-table">

                        {tools.map((tool, index) => (
                                <Box sx={{ width: 290, position:'relative' }} >
                                <Card  className='card_box'>
                                <CardContent sx={{ padding: '16px 80px 16px 16px' }}>
                                        <Typography gutterBottom sx={{ color: 'white !important', fontSize: 20 }}>
                                        {t(tool.title)}
                                        </Typography>
                                        <div className="tools_card_img">
                                            <img alt={`Logo d'illustration pour ${tool.title}`} src={tool.image} />
                                        </div>
                                        <a className='main_button' target="_blank noreferrer" href={tool.link}>{t('En savoir plus')}</a>
                                </CardContent>
                                </Card>
                                </Box>
                    )) }
            

                </div>

            </div>
            </>
        )
    }


export default Skills